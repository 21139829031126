<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <input-icon-color
          :placeholder="label"
          :label="label"
          :selectedColor="corFeicoes"
          :selectedIcon="iconeFeicoes"
          :displayInputIcon="tipoDadoGeografico == 'PONTO'"
          @colorSelected="handleColorSelected"
          @iconSelected="handleIconSelected"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    InputIconColor: () => import('@/components/general/InputIconColor.vue')
  },
  props: {
    tipoDadoGeografico: {
      type: String,
      required: true
    }
  },
  computed: {
    label() {
      let label = 'Cor ';
      switch (this.tipoDadoGeografico) {
        case 'POLIGONO':
          label += 'dos polígonos';
          break;
        case 'LINHA':
          label += 'das linhas';
          break;
        default:
          label = 'Ícone dos pontos';
      }
      return `${label} no mapa`;
    },
    corFeicoes() {
      return this.$store.getters.getSimbologiaConfigSimples.corFeicoes;
    },
    iconeFeicoes() {
      return this.$store.getters.getSimbologiaConfigSimples.iconeFeicoes;
    }
  },
  methods: {
    handleColorSelected(e) {
      this.$store.dispatch('setSimbologiaConfigSimplesCorFeicoes', e);
    },
    handleIconSelected(e) {
      this.$store.dispatch('setSimbologiaConfigSimplesIconeFeicoes', e);
    }
  }
};
</script>
